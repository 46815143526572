<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                v_companyprice_latest {{perf}}
                <DataTable :value="companypricelatests" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="price_date" header="日付" :sortable="true" :style="{'min-width': '10rem'}">
                    </Column>                    
                    <Column field="price_timestamp" header="時刻" :sortable="true"></Column>
                    <Column field="meigara_cd" header="銘柄コード" filterField="meigara_cd" filterMatchMode="contains" ref="meigara_cd" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                        </template>
                    </Column>
                    <Column field="meigara_name" header="銘柄名" filterField="meigara_name" filterMatchMode="contains" ref="meigara_name" :sortable="true" :style="{'min-width': '10rem'}">
                      <template #filter="{filterModel,filterCallback}">
                          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                      </template>
                    </Column>                    
                    <Column field="high" header="高値" :sortable="true">
                    </Column>
                    <Column field="low" header="安値" :sortable="true">
                    </Column>
                    <Column field="open" header="始値" :sortable="true">    
                    </Column>               
                    <Column field="close" header="終値" :sortable="true"> 
                    </Column>
                    <Column field="volume" header="出来高" :sortable="true"></Column>
                    <Column field="adj_close" header="調整後終値" :sortable="true"></Column>
                    <Column field="ago_today_diff" header="前日比" :sortable="true">
                      <template #body="slotProps">
                        <div :class="priceClass(slotProps.data)">
                          {{slotProps.data.ago_today_diff}}
                        </div>
                      </template>
                    </Column>
                    <Column field="ago_today_ratio" header="前日比%" :sortable="true">
                      <template #body="slotProps">
                        <div :class="priceClass(slotProps.data)">
                          {{slotProps.data.ago_today_ratio}}
                        </div>
                      </template>                    
                    </Column>
                    <Column field="volume_ratio" header="出来高前日比%" :sortable="true"></Column>
                    <Column field="info_source" header="情報源" :sortable="true"></Column>
                    <Column field="per" header="per" :sortable="true"></Column>
                    <Column field="pbr" header="pbr" :sortable="true"></Column>
                    <Column field="yield" header="yield" :sortable="true"></Column>
                    <Column field="margin_ratio" header="margin_ratio" :sortable="true"></Column>
                    <Column field="market_value_str" header="market_value_str" :sortable="true"></Column>
                    <Column field="settlement_date" header="settlement_date" :sortable="true"></Column>
                    <Column field="rela_meigara_cd_list" header="rela_meigara_cd_list" :sortable="true"></Column>
                    <Column field="rela_meigara_name_list" header="rela_meigara_name_list" :sortable="true"></Column>
                    <Column field="rela_at_ratio" header="rela_at_ratio" :sortable="true"></Column>
                    <Column field="rstrength" header="rstrength" :sortable="true">
                      <template #body="slotProps">
                        <div :class="rstrength_priceClass(slotProps.data)">
                          {{slotProps.data.rstrength}}
                        </div>
                      </template>                    
                    </Column>
                    <Column field="cont_class" header="cont_class" :sortable="true"></Column>
                    <Column field="taishaku_kubun" header="taishaku_kubun" :sortable="true"></Column>
                    <Column field="market_name" header="market_name" :sortable="true"></Column>
                    <Column field="market_at_ratio" header="market_at_ratio" :sortable="true"></Column>
                    <Column field="mrstrength" header="mrstrength" :sortable="true">
                      <template #body="slotProps">
                        <div :class="mrstrength_priceClass(slotProps.data)">
                          {{slotProps.data.mrstrength}}
                        </div>
                      </template>
                    </Column>
                    <Column field="ma5" header="ma5" :sortable="true"></Column>
                    <Column field="ma5_dratio" header="ma5_dratio" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma5dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma5_dratio}}
                        </div>
                      </template>
                    </Column>
                    <Column field="ma10" header="ma10" :sortable="true"></Column>
                    <Column field="ma10_dratio" header="ma10_dratio" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma10dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma10_dratio}}
                        </div>
                      </template>                    
                    </Column>
                    <Column field="ma25" header="ma25" :sortable="true"></Column>
                    <Column field="ma25_dratio" header="ma25_dratio" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma25dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma25_dratio}}
                        </div>
                      </template>                    
                    </Column>
                    <Column field="ma50" header="ma50" :sortable="true"></Column>
                    <Column field="ma50_dratio" header="ma50_dratio" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma50dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma50_dratio}}
                        </div>
                      </template>                     
                    </Column>
                    <Column field="ma75" header="ma75" :sortable="true"></Column>
                    <Column field="ma75_dratio" header="ma75_dratio" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma75dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma75_dratio}}
                        </div>
                      </template>
                    </Column>
                    <Column field="gap_ratio" header="gap_ratio" :sortable="true">
                      <template #body="slotProps">
                        <div :class="gapratio_priceClass(slotProps.data)">
                          {{slotProps.data.gap_ratio}}
                        </div>
                      </template>                    
                    </Column>
                    <Column field="vola_ratio" header="vola_ratio" :sortable="true">
                      <template #body="slotProps">
                        <div :class="volaratio_priceClass(slotProps.data)">
                          {{slotProps.data.vola_ratio}}
                        </div>
                      </template>                     
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import CompanypricelatestService from '@/service/CompanypricelatestService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            companypricelatests: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'meigara_cd': {value: '', matchMode: 'contains'},
                'meigara_name': {value: '', matchMode: 'contains'},
            },
            perf: null,
        }
    },
    companypricelatestService: null,
    created() {
        this.companypricelatestService = new CompanypricelatestService();
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: "id",
            sortOrder: "-1",
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            setTimeout(() => {
                this.companypricelatestService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )})
                        .then(data => {
                            this.companypricelatests = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.loading = false;
                            this.perf = data.perf;                            
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        },
        priceClass(data) {
          return [
            {
              'positive': data.ago_today_diff > 0,
              'negative': data.ago_today_diff < 0,
              'neutral': data.ago_today_diff == 0,
            }
          ];
        },
        rstrength_priceClass(data) {
          return [
            {
              'positive': data.rstrength > 0,
              'negative': data.rstrength < 0,
              'neutral': data.rstrength == 0,
            }
          ];
        },        
        mrstrength_priceClass(data) {
          return [
            {
              'positive': data.mrstrength > 0,
              'negative': data.mrstrength < 0,
              'neutral': data.mrstrength == 0,
            }
          ];
        },
        ma5dratio_priceClass(data) {
          return [
            {
              'positive': data.ma5_dratio > 0,
              'negative': data.ma5_dratio < 0,
              'neutral': data.ma5_dratio == 0,
            }
          ];
        },
        ma10dratio_priceClass(data) {
          return [
            {
              'positive': data.ma10_dratio > 0,
              'negative': data.ma10_dratio < 0,
              'neutral': data.ma10_dratio == 0,
            }
          ];
        },
        ma25dratio_priceClass(data) {
          return [
            {
              'positive': data.ma25_dratio > 0,
              'negative': data.ma25_dratio < 0,
              'neutral': data.ma25_dratio == 0,
            }
          ];
        },
        ma50dratio_priceClass(data) {
          return [
            {
              'positive': data.ma50_dratio > 0,
              'negative': data.ma50_dratio < 0,
              'neutral': data.ma50_dratio == 0,
            }
          ];
        },
        ma75dratio_priceClass(data) {
          return [
            {
              'positive': data.ma75_dratio > 0,
              'negative': data.ma75_dratio < 0,
              'neutral': data.ma75_dratio == 0,
            }
          ];
        }, 
        gapratio_priceClass(data) {
          return [
            {
              'positive': data.gap_ratio > 0,
              'negative': data.gap_ratio < 0,
              'neutral': data.gap_ratio == 0,
            }
          ];
        },
        volaratio_priceClass(data) {
          return [
            {
              'positive': data.vola_ratio > 0,
              'negative': data.vola_ratio < 0,
              'neutral': data.vola_ratio == 0,
            }
          ];
        },                                                       
      }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/prices.scss';
</style>